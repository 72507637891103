import { callApi } from ".";

export const getCallDashboardMetrics = async () => {
  return await callApi({
    method: "GET",
    url: `/call/metrics`,
    serializerFunc: (response: any) => response.data,
  });
};

export const processCall = async ({ key, language }) => {
  return await callApi({
    method: "POST",
    url: "/call",
    requestBody: { key, language },
    serializerFunc: (response: any) => response.data,
  });
};

export const getAllCalls = async ({
  scriptAdherence = false,
  abuseDetection = false,
  paymentIntent = false,
  highDuration = false,
}: {
  scriptAdherence?: boolean;
  abuseDetection?: boolean;
  paymentIntent?: boolean;
  highDuration?: boolean;
  }) => {
  const query = new URLSearchParams();
  if (scriptAdherence) query.append("scriptAdherence", "true");
  if (abuseDetection) query.append("abuseDetection", "true");
  if (paymentIntent) query.append("paymentIntent", "true");
  if (highDuration) query.append("highDuration", "true");
  return await callApi({
    method: "GET",
    url: `/call/all?${query.toString()}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const getCall = async ({ callId }) => {
  return await callApi({
    method: "GET",
    url: `/call?callId=${callId}`,
    serializerFunc: (response: any) => response.data,
  });
};

export const getCustomerSupportCallAnalysis = async ({ url }) => {
  return await callApi({
    method: "POST",
    url: '/call/agent-evaluation',
    requestBody: { url, language:'en' },
    serializerFunc: (response: any) => response.data,
  });
};

export const deleteCall = async ({ callId }) => {
  return await callApi({
    method: "DELETE",
    url: `/call?callId=${callId}`,
    serializerFunc: (response: any) => response.data,
  });
}