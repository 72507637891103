import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const runAIQuery = async (
  prompt: string,
  systemPrompt: string
): Promise<string> => {
  const response = await openai.chat.completions.create({
    model: "gpt-4o",
    response_format: {
      type: "json_object",
    },
    messages: [
      {
        role: "system",
        content: systemPrompt,
      },
      {
        role: "user",
        content: prompt,
      },
    ],
  });
  return response.choices[0].message.content as string;
};

export const detectAbuseInTranscript = async (
  transcript: string[]
): Promise<{ isAbusive: boolean; snippetId: number }> => {
  const systemPrompt = `
    You are an expert in analyzing conversations in Hinglish (Hindi + English) and figuring out
    if the speaker is being abusive. You have been given a transcript of a spoken audio clip and
    you need to determine if the speaker is being abusive. You need to return a boolean value
    indicating if the speaker is being abusive or not.
    
    Abuse can be directly using abusive words, offensive remarks on family, casteist, religious or
    sexist remarks, derogatory comments, disrespect, death threats, etc. Each of these should be
    flagged as abusive content.

    The transcript provided to you will be in snippets. You need to identify if the selected snippet
    contains abusive content or not. The entire transcript is given to you to ensure that you can
    identify the context of the conversation and make a better decision.

    The response should be in the following JSON format:

    {
        "isAbusive": boolean
    }
    `;
  const userPrompt = `
    Here's the transcript:

    ${transcript.map((snippet, index) => "\t" + snippet + "\n").join("")}
    
    Selected snippet: ${transcript[transcript.length - 1]}
    `;
  try {
    const response = await runAIQuery(userPrompt, systemPrompt);
    const parsedResponse = JSON.parse(response);
    const isAbusive = parsedResponse.isAbusive ?? false;
    return { isAbusive, snippetId: transcript.length - 1 };
  } catch (error: any) {
    throw new Error(error.message);
  }
};
