import React from 'react';
import { Layout } from 'antd';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    SearchBox,
    Hits,
    Highlight,
    connectStateResults
} from 'react-instantsearch-dom';
import './CallRepoSearch.css'

const { Content } = Layout;

const searchClient = algoliasearch('XT4FMRERU9', '9017d2ee80ee4fe48a1a0cb4f0345928');

interface HitProps {
    hit: {
        groupId: string;
        chunkStartTime: number;
        chunkEndTime: number;
        chunkText: string;
        callUrl: string;
        objectID: string;
    };
}

const SearchResult: React.FC<HitProps> = ({ hit }) => (
    <div className="ais-Hits-item card">
        <strong>Group ID: {hit.groupId}</strong>
        <p>Start Time: {hit.chunkStartTime}</p>
        <p>End Time: {hit.chunkEndTime}</p>
        <p>Text: <Highlight attribute="chunkText" hit={hit} /></p>
        <p>Call URL: <a href={hit.callUrl}>{hit.callUrl}</a></p>
    </div>
);

const Results = connectStateResults(
    ({ searchState, searchResults, children }: any) =>
        searchResults && searchResults.nbHits !== 0 ? (
            children
        ) : (
            <div>No results found for "{searchState.query}"</div>
        )
);

const CallRepositorySearch: React.FC = () => {
    return (
        <Content className="main-content">
            <h2>Call Repository Search</h2>
            <InstantSearch searchClient={searchClient} indexName="transcripts">
                <div className="search-bar">
                    <SearchBox />
                </div>
                <Results>
                    <div className="result-grid">
                        <Hits hitComponent={SearchResult as any} />
                    </div>
                </Results>
            </InstantSearch>
        </Content>
    );
};

export default CallRepositorySearch;
