import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const runAIQuery = async (
  prompt: string,
  systemPrompt: string
): Promise<string> => {
  const response = await openai.chat.completions.create({
    model: "gpt-4o",
    response_format: {
      type: "json_object",
    },
    messages: [
      {
        role: "system",
        content: systemPrompt,
      },
      {
        role: "user",
        content: prompt,
      },
    ],
  });
  return response.choices[0].message.content as string;
};

export const getAgentAssistHint = async (
  transcript: string[]
): Promise<{ prompt: string; snippetId: number }> => {
  const systemPrompt = `
    You are an expert in analyzing conversations in Hinglish (Hindi + English), specifically for debt collection calls. Your task is to assist the agent by providing a helpful prompt based on the customer's statements in the transcript. The goal is to guide the agent in responding effectively and professionally to encourage the customer to make payments or comply with necessary steps.

    The transcript provided to you may be in snippets, but the entire conversation is given to ensure you understand the context. Your response should focus on suggesting what the agent should say to the customer next, helping them navigate the debt collection process.

    The response should be in the following JSON format:
    { "prompt": string }

    Where prompt is a helpful suggestion of under 100 characters, in simple English for the agent to continue the conversation

    For instance:
1. Transcript - "I am unable to pay back the loan since I lost my job and need the money for livelihood."
   Prompt - "Ask if the customer is able to pay a small amount today, and offer to repurpose the loan."

2. Transcript - "I don't have money because I met with an accident."
   Prompt - "Request a medical certificate from the customer."

3. Transcript - "I won't give the money back, do what you want."
   Prompt - "Warn that their credit score will drop if they don't repay, which will affect future loans."

4. Transcript - "I was unaware of the payment date and why is this interest rate so high?"
   Prompt - "Explain the terms of the loan and clarify the interest rate."

5. Transcript - "My father took the loan and he passed away."
   Prompt - "Ask the customer to share the death certificate on WhatsApp."

Your goal is to ensure the conversation stays professional and effective, assisting the agent with useful prompts to steer the conversation productively.
    
    `;
  const userPrompt = `
    Here's the transcript:

    ${transcript.map((snippet, index) => "\t" + snippet + "\n").join("")}
    
    Selected snippet: ${transcript[transcript.length - 1]}
    `;
  try {
    const response = await runAIQuery(userPrompt, systemPrompt);
    const parsedResponse = JSON.parse(response);
    const prompt = parsedResponse.prompt ?? "";
    return { prompt, snippetId: transcript.length - 1 };
  } catch (error: any) {
    throw new Error(error.message);
  }
};
