import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './AbusiveCalls.css';
import { Link } from 'react-router-dom';
import { getAllCalls } from '../../api/call.api';
import { RoutePath } from '../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface CallData {
    agentId: string;
    callDateTime: string;
    description: string;
    callId: string;
}

const AbusiveCalls: React.FC = () => {
    const [data, setData] = useState<CallData[]>([]);

    useEffect(() => {
        getAllCalls({ abuseDetection: true }).then((data) => {
          const calls = data.map((call: any) => {
            return {
              agentId: call.aId,
              callDateTime: new Date(call.timestamp ?? Date.now()).toLocaleString(),
              description: call.abuseDescription,
              callId: call.callId,
            };
          });
          setData(calls.sort((a, b) => new Date(b.callDateTime).getTime() - new Date(a.callDateTime).getTime()));
        });
    }, []);

    const columns: ColumnsType<CallData> = [
        {
            title: 'Agent ID',
            dataIndex: 'agentId',
            key: 'agentId',
        },
        {
            title: 'Call Date & Time',
            dataIndex: 'callDateTime',
            key: 'callDateTime',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => {
                return <span>{text}</span>;
            },
        },
        {
        dataIndex: "callId",
        key: "callId",
        render: (callId:string) => (
          <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`} className="btn btn-outline-secondary btn-sm">
            View
          </Link>
        ),
      },
    ];

    return (
        <div className="main-content">
            <h2>Abusive Calls</h2>
            <Table columns={columns} dataSource={data} rowKey="agentId" />
        </div>
    );
};

export default AbusiveCalls;
