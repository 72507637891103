import React, { useState, useEffect } from 'react';
import { Layout, Card, Row, Col, Spin, TableProps, Table } from 'antd';
import './MainContent.css'; // Custom styles
import { getCallDashboardMetrics } from '../../api/call.api';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getAverageScriptScores } from '../../api/agent.api';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from "recharts";

const { Content } = Layout;

interface AverageScriptScoreData {
  agentId: string;
  name: string;
  avgScore: number;
  scores: number[];
}

const MainContent: React.FC = () => {
    const [totalCalls, setTotalCalls] = useState(0);
    const [abusiveCalls, setAbusiveCalls] = useState(0);
    const [averageCallTime, setAverageCallTime] = useState('0m 0s');
    const [noOfWillingIntentCalls, setNoOfWillingIntentCalls] = useState(0);
    const [noOfUnwillingIntentCalls, setNoOfUnwillingIntentCalls] = useState(0);
    const [noOfUnConclusiveIntentCalls, setNoOfUnConclusiveIntentCalls] = useState(0);
    const [averageScriptScores, setAverageScriptScores] = useState<AverageScriptScoreData[]>([]);
    const [activeAgentId, setActiveAgentId] = useState<string | null>(null);

    const userId = useSelector((state: RootState) => state.persisted.user.value)._id;

    useEffect(() => {
        getCallDashboardMetrics().then((data) => {
          setTotalCalls(data.totalNoOfCalls);
          setAbusiveCalls(data.noOfAbuseDetectedCalls);
          const minutes = Math.floor(data.averageCallDuration / 60);
          const seconds = Math.round(data.averageCallDuration % 60);
          setAverageCallTime(`${minutes}m ${seconds}s`);
          setNoOfWillingIntentCalls(data.noOfWillingIntentCalls);
          setNoOfUnwillingIntentCalls(data.noOfUnwillingIntentCalls);
          setNoOfUnConclusiveIntentCalls(data.noOfUnConclusiveIntentCalls);
        });
        getAverageScriptScores().then((data) => {
          console.log(data);
          setAverageScriptScores(data.map((item) => {
            return {
              agentId: item.agentId,
              name: item.name,
              avgScore: item.averageScriptScore,
              scores: item.scriptScores
            }
          }));
          setActiveAgentId(data[0]?.agentId);
        })
    }, [])
  
    const ScorePlot = () =>{
      if (activeAgentId === null) {
        return <Spin />;
      }
      else {
        const data = averageScriptScores.find((item) => item.agentId === activeAgentId)?.scores?.map((item, n) => ({ call: n + 1, score: item }));
        const allAgentsAverage = averageScriptScores.map((item) => item.avgScore).reduce((a, b) => a + b, 0) / averageScriptScores.length;
        return (
          <ResponsiveContainer width="100%" height="90%">
            <LineChart
              // width={500}
              // height={300}
              data={data}
              // margin={{
              //   top: 20,
              //   right: 50,
              //   left: 20,
              //   bottom: 5,
              // }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="call" />
              <YAxis domain={[0, 10]} />
              <Tooltip />
              <ReferenceLine y={allAgentsAverage} label="Average" stroke="red" />
              <Line dataKey="score" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        );
      }
    }

    const AgentsTable = ({ count, highToLow }) => {
      const data = averageScriptScores.sort((a, b) => highToLow ? b.avgScore - a.avgScore : a.avgScore - b.avgScore).slice(0, count);

      const columns: TableProps<AverageScriptScoreData>["columns"] = [
        {
          title: 'Agent Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Average Score',
          dataIndex: 'avgScore',
          key: 'avgScore',
        }
      ]
      return (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName="agent-table-row"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => setActiveAgentId(record.agentId),
            };
          }}
        />
      );
    }
    
    return (
      <Content className="main-content">
        <h2>Call Auditing Dashboard</h2>
        <Row gutter={[16, 16]} style={{ rowGap: "0px" }}>
          <Col span={8}>
            <Link to={RoutePath.ALL_CALLS}>
              <Card className="metric-card">
                <div className="card-title">Total Number of Calls</div>
                <p>{totalCalls}</p>
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`${RoutePath.INTENT}?isWilling=true`}>
              <Card className="metric-card green">
                <div className="card-title">
                  Number of Calls with Payment Willing Intent
                </div>
                <p>{noOfWillingIntentCalls}</p>
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`${RoutePath.INTENT}?isWilling=false`}>
              <Card className="metric-card red">
                <div className="card-title">
                  Number of Calls with Payment Unwilling Intent
                </div>
                <p>{noOfUnwillingIntentCalls}</p>
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`${RoutePath.INTENT}?isWilling=null`}>
              <Card className="metric-card gray">
                <div className="card-title">
                  Number of Calls with Unconclusive Payment Intent
                </div>
                <p>{noOfUnConclusiveIntentCalls}</p>
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={RoutePath.ABUSE}>
              <Card className="metric-card">
                <div className="card-title">Number of Non-Compliant Calls</div>
                <p>{abusiveCalls}</p>
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={RoutePath.DURATION}>
              <Card className="metric-card">
                <div className="card-title">Average Call Time</div>
                <p>{averageCallTime}</p>
              </Card>
            </Link>
          </Col>
          {/* <Col span={16}>
            <div className="chart-card">
              <div className="card-title" style={{ paddingBottom: "15px" }}>
                Agent{" "}
                {
                  averageScriptScores.find(
                    (item) => item.agentId === activeAgentId
                  )?.name
                }
                's Performance Chart
              </div>
              <ScorePlot />
            </div>
          </Col>
          <Col span={8}>
            <div className="chart-card">
              <div className="card-title" style={{ paddingBottom: "15px" }}>
                Top 5 Best Performing Agents
              </div>
              <AgentsTable count={5} highToLow={true} />
            </div>
          </Col> */}
        </Row>
      </Content>
    );
};

export default MainContent;
