import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../models/user.model";

export const initialUserState: { value: User } = {
  value: {
    _id: "",
    token: "",
    email: "",
    name: "",
    photoUrl: "",
    usageLimit: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser(state: { value: User }, action: PayloadAction<User>) {
      state.value = action.payload;
    },
    setUserToken(state: { value: User }, action: PayloadAction<string>) {
      state.value.token = action.payload;
    },
    clearUser(state: { value: User }) {
      state.value = initialUserState.value
    }
  },
});

export const { setUser, setUserToken, clearUser } = userSlice.actions;

export default userSlice.reducer;
