import React, { useState, useRef, useEffect } from "react";
import "./LiveAbuseDetection.css";
import { Button } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { detectAbuseInTranscript } from "./detectAbuse";

const LiveAbuseDetection: React.FC = () => {
  const [transcript, setTranscript] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isAbuseDetected, setIsAbuseDetected] = useState<boolean>(false);
  const [recordingStarted, setRecordingStarted] = useState<boolean>(false);
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const socketRef = useRef<WebSocket | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const silenceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const changeDivStyle = (parentId: string, n: number, color: string) => {
    const parentDiv = document.getElementById(parentId);

    if (parentDiv) {
      const nthChild = parentDiv.children[n] as HTMLElement;

      if (nthChild) {
        nthChild.style.color = color;
        nthChild.style.fontWeight = "bold";
      } else {
      }
    } else {
    }
  };

  const initializeTranscription = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      if (!MediaRecorder.isTypeSupported("audio/webm")) {
        alert("Browser not supported");
        return;
      }
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });
      mediaRecorderRef.current = mediaRecorder;

      const socket = new WebSocket(
        "wss://api.deepgram.com/v1/listen?model=nova-2&language=hi",
        ["token", process.env.REACT_APP_DEEPGRAM_API_KEY!]
      );

      socketRef.current = socket;

      socket.onopen = () => {
        setIsConnected(true);
        setIsRecording(true);
        setRecordingStarted(true);
        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
            resetSilenceTimeout();
          }
        });
        mediaRecorder.start(1000);
        startTimer();
      };

      socket.onmessage = async (message) => {
        const received = JSON.parse(message.data);
        const newTranscript = received.channel.alternatives[0].transcript;
        if (newTranscript && received.is_final) {
          setTranscript((prevTranscript) => {
            const updatedTranscript = [...prevTranscript, newTranscript];
            detectAbuseInTranscript(updatedTranscript).then((response) => {
              if (response.isAbusive) {
                stopRecording();
                setIsAbuseDetected(true);
                changeDivStyle("transcript-div", response.snippetId, "red");
              }
            });
            return updatedTranscript;
          });
        }
      };

      socket.onclose = () => {
        setIsConnected(false);
        setIsRecording(false);
        stopTimer();
      };

      socket.onerror = (error) => {
        setIsRecording(false);
        stopTimer();
      };

      startSilenceTimeout();
    } catch (error) {}
  };

  const startRecording = () => {
    if (!socketRef.current) {
      initializeTranscription();
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (socketRef.current) {
      socketRef.current.close();
    }
    setIsRecording(false);
    clearSilenceTimeout();
    stopTimer();
  };

  const clearRecording = () => {
    setTranscript([]);
    setRecordingStarted(false);
    setTimeElapsed(0);
    setIsConnected(false);
    setIsRecording(false);
    setIsAbuseDetected(false)
    socketRef.current = null;
    mediaRecorderRef.current = null;
  };

  const startSilenceTimeout = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      if (socketRef.current) {
        socketRef.current.close();
        setIsRecording(false);
        setIsConnected(false);
        stopTimer();
      }
    }, 5000);
  };

  const resetSilenceTimeout = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
    startSilenceTimeout();
  };

  const clearSilenceTimeout = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    return () => {
      clearSilenceTimeout();
      stopTimer();
    };
  }, []);

  return (
    <div className="main-content">
      <h2>Live Abuse Detection</h2>
      <div className="grid-container">
        <div className="mic-box">
          <div>
            <Button onClick={isRecording ? stopRecording : startRecording}>
              {isRecording ? "⬛ " : <AudioOutlined />}
              {isRecording ? "Stop Recording" : "Start Recording"}
            </Button>
            <div style={{ textAlign: "center", padding: "10px" }}>
              {(isConnected || (recordingStarted && !isRecording)) &&
                ` 🔴 ${formatTime(timeElapsed)}`}
            </div>
            <div style={{ textAlign: "center", padding: "10px" }}>
              {recordingStarted && !isRecording && (
                <Button onClick={clearRecording} style={{ marginLeft: "10px" }}>
                  Clear
                </Button>
              )}
            </div>
            <div style={{ textAlign: "center", padding: "10px" }}>
              {isAbuseDetected && "Abusive content detected"}
            </div>
          </div>
        </div>
        <div className="transcript-box">
          <div
            style={{ fontWeight: 600, fontSize: "1rem", marginBottom: "10px" }}
          >
            Transcript
          </div>
          <div id="transcript-div">
            {transcript.map((snippet, id) => (
              <div key={id}>{snippet}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveAbuseDetection;
