import React, { useEffect, useRef, useState } from "react";
import { InboxOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { message, Upload, UploadProps, Select, Button, Card, Spin, Table } from "antd";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import toast from "react-hot-toast";
import { getCustomerSupportCallAnalysis } from "../../api/call.api";
import "./CustomerSupportCallAnalysis.css";
import { ColumnsType } from "antd/es/table";

const { Dragger } = Upload;

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  dirName: process.env.REACT_APP_S3_DIR_NAME,
  region: process.env.REACT_APP_AWS_REGION_NAME,
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

interface Snippet {
  start: number;
  end: number;
  speaker: string;
  text: string;
}

interface AbusiveContentItem {
  content: string;
  remarks: string;
  startTimestamp: number;
  endTimestamp: number;
}

interface AbuseDetectionData {
  isAbuseDetected: boolean;
  abuseDescription: string;
  abusiveContent: AbusiveContentItem[];
}

interface AgentScript {
  query: string;
  route: string[];
  description: string;
  questions: string[]
}

interface AgentEvaluationItem {
  guideline: string;
  asked: boolean;
  remark: string;
}

const CustomerSupportCallAnalysis = () => {

    const [isProcessFileClicked, setIsProcessFileClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snippets, setSnippets] = useState<Snippet[]>([]);
    const [abuseDetection, setAbuseDetection] = useState<AbuseDetectionData | null>(null);
    const [uploadedFile, setUploadedFile] = useState<{ url: string, name: string }>({ url: "", name: "" });
    const [audioTimestamp, setAudioTimestamp] = useState< { start: number, end: number } |null>(null);
    const [agentScriptData, setAgentScriptData] = useState<AgentScript | null>(null);
    const [agentEvaluationData, setAgentEvaluationData] = useState<AgentEvaluationItem[]>([]);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    
    useEffect(() => {
      if (audioRef.current && audioTimestamp !== null) {
        const audio = audioRef.current;
        const handleTimeUpdate = () => {
          if (audio.currentTime >= audioTimestamp.end) {
            audio.pause();
            audio.removeEventListener("timeupdate", handleTimeUpdate);
          }
        };
        audio.currentTime = audioTimestamp.start;
        audio.play();
        audio.scrollIntoView({ behavior: "smooth" });
        audio.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
          audio.removeEventListener("timeupdate", handleTimeUpdate);
        };
      }
    }, [audioTimestamp]);

    const handlePlayAudioClick = (start: number, end: number) => {
      setAudioTimestamp({ start, end });
    };

    const handleFileUpload = async (file: any, config: any) => {
      const s3 = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region,
        httpOptions: {
          timeout: 300000,
        },
      });

      const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
      const params = {
        Bucket: config.bucketName,
        Key: `${config.dirName}/${fileName}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params).send(async (err: any, data: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    };
    
    const uploadProps: UploadProps = {
      name: "file",
      multiple: false,
      showUploadList: false,
      beforeUpload: async (file: any) => {
        try {
          const toastId = toast.loading(`Uploading file '${file.name}'...`);
          const data: any = await handleFileUpload(file, config as any);
          toast.success(`File '${file.name}' uploaded successfully.`, {
            id: toastId,
          });
          setUploadedFile({ url: data.Location, name: file.name });
        } catch (error: any) {
          message.error(`Upload failed: ${error.message}.`);
        }
        return false;
      },
    };

    const abuseDetectionColumns: ColumnsType<AbusiveContentItem> = [
      {
        title: "Content",
        dataIndex: "content",
        key: "content",
        width: "20%",
    },
    {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        width: "65%",
    },
    {
        dataIndex: ["startTimestamp", "endTimestamp"],
        key: "timestamp",
        width: "15%",
        render: (value, record:AbusiveContentItem) => (
          <a onClick={() => handlePlayAudioClick(record.startTimestamp - 5, record.endTimestamp + 5)}>
            <PlayCircleOutlined /> {" "}  Play
          </a>
        ),
      },
    ];

    const agentEvaluationColumns: ColumnsType<AgentEvaluationItem> = [
      {
        title: "Guideline",
        dataIndex: "guideline",
        key: "guideline",
      },
      {
        title: "",
        dataIndex: "asked",
        key: "asked",
        render: (value:boolean) => (
            <span>{value ? "✅" : "❌"}</span>
        ),
      },
      {
        title: "Remarks",
        dataIndex: "remark",
        key: "remark",
      }
    ];

    const handleProcessFile = async () => {
        setIsProcessFileClicked(true);
        setIsLoading(true)
        const callAnalysis = await getCustomerSupportCallAnalysis({ url: uploadedFile.url });
        setSnippets(callAnalysis.processedDiarizedTranscript);
        setAbuseDetection(callAnalysis.detectedAbuse);
        setAgentScriptData(callAnalysis.agentEvaluation.agentQuestionObj);
        setAgentEvaluationData(callAnalysis.agentEvaluation.splitUp);
        setIsLoading(false)
    }
  
  if(isProcessFileClicked)
    return (
      <div className="main-div">
        <h2>Call Analysis</h2>
        <div className="call-analysis-main">
          <div>
            <div className="payment-intent-box">
              <Card title="Customer Query">
                {isLoading ? (
                  <div>
                    <Spin size="large" />
                  </div>
                ) : (
                  <div>
                    <strong>Route:</strong> {agentScriptData.route.join(" > ")}
                  </div>
                )}
              </Card>
            </div>
            <div className="areas-of-improvement-box">
              <Card title="Guideline Adherence">
                {isLoading ? (
                  <div>
                    <Spin size="large" />
                  </div>
                ) : (
                  <Table
                    dataSource={agentEvaluationData}
                    columns={agentEvaluationColumns}
                    pagination={false}
                    rowKey="section"
                  />
                )}
              </Card>
            </div>
            <div className="abuse-detection-box">
              <Card title="Abuse Detection">
                {isLoading ? (
                  <div>
                    <Spin size="large" />
                  </div>
                ) : abuseDetection ? (
                  abuseDetection.isAbuseDetected ? (
                    <div>
                      {/* <p><strong>Language:</strong> {abuseDetection.abuseDescription}</p> */}
                      <Table
                        dataSource={abuseDetection.abusiveContent}
                        columns={abuseDetectionColumns}
                        pagination={false}
                        rowKey="content"
                      />
                    </div>
                  ) : (
                    <p>No abusive content detected</p>
                  )
                ) : (
                  <p>Error loading abuse detection data</p>
                )}
              </Card>
            </div>
          </div>
          <div className="play-call-box">
            <Card title="Play Call" style={{ height: "600px" }}>
              {uploadedFile.url && (
                <audio ref={audioRef} controls id="callAudio">
                  <source src={uploadedFile.url} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}
              <div className="transcript-box" style={{ marginTop: "20px" }}>
                {isLoading ? (
                  <Spin size="large" />
                ) : (
                  snippets.map((snippet, index) => (
                    <div
                      key={index}
                      data-start={snippet.start}
                      data-end={snippet.end}
                      id={`snippet-${index}`}
                    >
                      <strong>{snippet.speaker}:</strong> {snippet.text}
                    </div>
                  ))
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="main-div">
        <h2>Upload Call</h2>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag a file to this area to upload. Only audio files are
            allowed.
          </p>
        </Dragger>
        {uploadedFile.url && (
          <>
            <h3>Uploaded Files</h3>
            <div>
              <a href={uploadedFile.url} target="_blank" rel="noreferrer">
                {uploadedFile.name}
              </a>
            <Button type="primary" onClick={handleProcessFile} style={{marginLeft:"10px"}}>
              Process File
            </Button>
            </div>
          </>
        )}
      </div>
    );
};

export default CustomerSupportCallAnalysis;
