import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 

import user from "./slices/user";

const persistConfig = {
  key: "root",
  storage,
};


const rootReducer = combineReducers({
    user
});

const persisted = persistReducer(persistConfig, rootReducer);

const configuredReducer = combineReducers({
  persisted
});

export const store = configureStore({
  reducer: configuredReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
