import React, { useState, useRef, useEffect } from "react";
import "./LiveAgentAssist.css";
import { Button } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { getAgentAssistHint } from "./agentAssist";

const LiveAgentAssist: React.FC = () => {
  const [transcript, setTranscript] = useState<string[]>([]);
  const [agentHint, setAgentHint] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingStarted, setRecordingStarted] = useState<boolean>(false);
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const [lastPromptTime, setLastPromptTime] = useState<number>(0); // Keep track of the last prompt time
  const socketRef = useRef<WebSocket | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const silenceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const PROMPT_INTERVAL = 5000; // Minimum interval of 5 seconds (5000 milliseconds) between prompts

  const initializeTranscription = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      if (!MediaRecorder.isTypeSupported("audio/webm")) {
        alert("Browser not supported");
        return;
      }
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });
      mediaRecorderRef.current = mediaRecorder;

      const socket = new WebSocket(
        "wss://api.deepgram.com/v1/listen?model=nova-2&language=hi",
        ["token", process.env.REACT_APP_DEEPGRAM_API_KEY!]
      );

      socketRef.current = socket;

      socket.onopen = () => {
        setIsConnected(true);
        setIsRecording(true);
        setRecordingStarted(true);
        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
            resetSilenceTimeout();
          }
        });
        mediaRecorder.start(1000);
        startTimer();
      };

      socket.onmessage = async (message) => {
        const received = JSON.parse(message.data);
        const newTranscript = received.channel.alternatives[0].transcript;
        if (newTranscript && received.is_final) {
          setTranscript((prevTranscript) => {
            const updatedTranscript = [...prevTranscript, newTranscript];

            const currentTime = Date.now();
            if (currentTime - lastPromptTime >= PROMPT_INTERVAL) {
              // Ensure at least 5 seconds between prompts
              getAgentAssistHint(updatedTranscript).then((response) => {
                if (response.prompt) {
                  setAgentHint(response.prompt);
                  setLastPromptTime(currentTime); // Update the last prompt time

                  // Add a class to trigger the animation
                  const agentHintBox = document.querySelector(".agent-hint-box");
                  if (agentHintBox) {
                    agentHintBox.classList.remove("animate-hint");
                    void (agentHintBox as any).offsetWidth; // Trigger reflow for the animation to restart
                    agentHintBox.classList.add("animate-hint");
                  }
                }
              });
            }

            return updatedTranscript;
          });
        }
      };

      socket.onclose = () => {
        setIsConnected(false);
        setIsRecording(false);
        stopTimer();
      };

      socket.onerror = (error) => {
        setIsRecording(false);
        stopTimer();
      };

      startSilenceTimeout();
    } catch (error) { }
  };

  const startRecording = () => {
    if (!socketRef.current) {
      initializeTranscription();
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (socketRef.current) {
      socketRef.current.close();
    }
    setIsRecording(false);
    clearSilenceTimeout();
    stopTimer();
  };

  const clearRecording = () => {
    setTranscript([]);
    setAgentHint(null);
    setRecordingStarted(false);
    setTimeElapsed(0);
    setIsConnected(false);
    setIsRecording(false);
    socketRef.current = null;
    mediaRecorderRef.current = null;
  };

  const startSilenceTimeout = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      if (socketRef.current) {
        socketRef.current.close();
        setIsRecording(false);
        setIsConnected(false);
        stopTimer();
      }
    }, 5000);
  };

  const resetSilenceTimeout = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
    startSilenceTimeout();
  };

  const clearSilenceTimeout = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    return () => {
      clearSilenceTimeout();
      stopTimer();
    };
  }, []);

  return (
    <div className="main-content">
      <h2>Live Agent Assist</h2>
      <div className="grid-container">
        <div className="mic-box">
          <div>
            <Button onClick={isRecording ? stopRecording : startRecording}>
              {isRecording ? "⬛ " : <AudioOutlined />}
              {isRecording ? "Stop Recording" : "Start Recording"}
            </Button>
            <div style={{ textAlign: "center", padding: "10px" }}>
              {(isConnected || (recordingStarted && !isRecording)) &&
                ` 🔴 ${formatTime(timeElapsed)}`}
            </div>
            <div style={{ textAlign: "center", padding: "10px" }}>
              {recordingStarted && !isRecording && (
                <Button onClick={clearRecording} style={{ marginLeft: "10px" }}>
                  Clear
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="transcript-box">
          <div
            style={{ fontWeight: 600, fontSize: "1rem", marginBottom: "10px" }}
          >
            Transcript
          </div>
          <div id="transcript-div">
            {transcript.map((snippet, id) => (
              <div key={id}>{snippet}</div>
            ))}
          </div>
        </div>
        <div className="agent-hint-box animate-hint">
          <div
            style={{ fontWeight: 600, fontSize: "1rem", marginBottom: "10px" }}
          >
            Agent Assist Prompt 💡
          </div>
          <div>{agentHint ? agentHint : "No prompt yet."}</div>
        </div>
      </div>
    </div>
  );
};

export default LiveAgentAssist;
