import { callApi } from "."


export const getAllAgents = async () => {
    return await callApi({
        method: "GET",
        url: `/agent/all`,
        serializerFunc: (response: any) => response.data
    })
}
export const createAgent = async ({ name }) => {
    return await callApi({
        method: "POST",
        url: '/agent',
        requestBody: { name },
        serializerFunc: (response: any) => response.data
    })
}
export const getAgentDashboardMetrics = async () => {
    return await callApi({
        method: "GET",
        url: `/agent/metrics`,
        serializerFunc: (response: any) => response.data
    })    
}

export const getAgentDetails = async ({ agentId }) => {
    return await callApi({
      method: "GET",
      url: `/agent?agentId=${agentId}`,
      serializerFunc: (response: any) => response.data,
    });
}

export const getAverageScriptScores = async () => {
    return await callApi({
      method: "GET",
      url: `/agent/average-script-scores`,
      serializerFunc: (response: any) => response.data,
    });
}