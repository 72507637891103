import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../routes';
import { getAgentDashboardMetrics } from '../../api/agent.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface AgentData {
    aId: string;
    agentId: string;
    agentName: string;
    email: string;
    percentageFlaggedCalls: number;
    callScore: number;
}

const AgentPerformance: React.FC = () => {
    const [data, setData] = useState<AgentData[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        getAgentDashboardMetrics()
            .then(data => {
                const agents = data.map(item => {
                    return {
                      aId: item.aId,
                      agentId: item.agentId,
                      email:item.email,
                      agentName: item.name,
                      percentageFlaggedCalls: item.percentageFlaggedCalls,
                      callScore: item.averageCallScore,
                    };
                })
                setData(agents);
            })
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const columns: ColumnsType<AgentData> = [
        {
            title: 'Agent ID',
            dataIndex: 'aId',
            key: 'aId',
            render: (text: string) => <div>{text}</div>,
        },
        {
            title: 'Agent Name',
            dataIndex: 'agentName',
            key: 'agentName',
            render: (text: string) => <div>{text}</div>,
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
            key: 'email',
            render: (text: string) => <div>{text}</div>,
        },
        {
            title: '% of Calls Flagged',
            dataIndex: 'percentageFlaggedCalls',
            key: 'percentageFlaggedCalls',
            render: (value: number) => {
                return <div>{value}</div>;
            },
        },
        {
            title: 'Call Score',
            dataIndex: 'callScore',
            key: 'callScore',
            render: (value: number) => {
                return <div>{value}/10</div>;
            },
        },
        {
            dataIndex: "agentId",
            key: "agentId",
            render: (agentId:string) => (
                <Link to= {`${RoutePath.AGENT}?agentId=${agentId}`} className="btn btn-outline-secondary btn-sm">View</Link>
            ),
        },
    ];

    const filteredData = data.filter(agent =>
        agent.agentName.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="main-content">
            <h2 className="py-2">Agent Performance Tracker</h2>
            {/* <div className="search-bar mb-3">
                <Input type="text" placeholder="Search Agent..." value={searchText} onChange={handleSearch} />
            </div> */}
            <Table columns={columns} dataSource={filteredData} rowKey="agentName" />
        </div>
    );
};

export default AgentPerformance;
