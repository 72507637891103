import React, { useEffect, useRef, useState } from 'react';
import { Layout, Card, Spin, Table, Button } from 'antd';
import { PlayCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from 'antd/es/table';
import './CallAnalysis.css';
import { deleteCall, getCall } from '../../api/call.api';
import { RoutePath } from '../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import AWS from "aws-sdk";

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  dirName: process.env.REACT_APP_S3_DIR_NAME,
  region: process.env.REACT_APP_AWS_REGION_NAME,
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

const { Content } = Layout;

interface TranscriptSnippet {
  start: number;
  end: number;
  speaker: string;
  text: string;
}

interface AbusiveContentItem {
    content: string;
    remarks: string;
    startTimestamp: number;
    endTimestamp: number;
}

interface AbuseDetectionData {
    isAbuseDetected: boolean;
    abuseDescription: string;
    abusiveContent: AbusiveContentItem[];
}

interface PaymentIntentData {
  isWilling: boolean | null;
  reason: string[];
  paymentDate: string;
  ptpScore?: number;
  ptpResult?: string;
  ptpIntentChecks?: Array<{
    check: string;
    passed: boolean;
    remarks: string;
  }>;
  noPtpType?: string;
}

interface AgentEvaluationItem {
  question: string;
  value: string;
  remarks: string;
}

interface CallModel {
  _id: string;
  agentId: string;
  userId: string;
  customerId: string;
  url: string;
  key: string;
  duration: number;
  timestamp: number;
  callScore: number;
  transcript: string;
  processStatus: string;
  diarizedTranscript: TranscriptSnippet[];
  abuseDetection: AbuseDetectionData;
  paymentIntent: PaymentIntentData;
  agentEvaluation: AgentEvaluationItem[];
}

const CallAnalysis: React.FC = () => {
    const callId = new URL(window.location.href).searchParams.get('callId');
    if (!callId) {
        window.location.href = RoutePath.HOME;
    }
    const navigate = useNavigate()
    const [audioUrl, setAudioUrl] = useState<string>("");
    const [audioTimestamp, setAudioTimestamp] = useState< { start: number, end: number } |null>(null);
    const [snippets, setSnippets] = useState<TranscriptSnippet[]>([]);
    const [abuseDetection, setAbuseDetection] = useState<AbuseDetectionData | null>(null);
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntentData | null>(null);
    const [agentEvaluation, setAgentEvaluation] = useState<AgentEvaluationItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        getCall({ callId }).then(async ({call}: { call: CallModel }) => {
          const signedUrl = await getSignedUrlForRead(call.key)
          setAudioUrl(signedUrl);
          setSnippets(call.diarizedTranscript);
          setAbuseDetection(call.abuseDetection);
          setPaymentIntent(call.paymentIntent);
          setAgentEvaluation(call.agentEvaluation);
          setLoading(false);
        });
    },[])
    
    useEffect(() => {
      if (audioRef.current && audioTimestamp !== null) {
        const audio = audioRef.current;
        const handleTimeUpdate = () => {
          if (audio.currentTime >= audioTimestamp.end) {
            audio.pause();
            audio.removeEventListener("timeupdate", handleTimeUpdate);
          }
        };
        audio.currentTime = audioTimestamp.start;
        audio.play();
        audio.scrollIntoView({ behavior: "smooth" });
        audio.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
          audio.removeEventListener("timeupdate", handleTimeUpdate);
        };
      }
    }, [audioTimestamp]);

    const handlePlayAudioClick = (start: number, end: number) => {
        setAudioTimestamp({start, end});
    }
    
    const getSignedUrlForRead = async (fileKey: string): Promise<string> => {
      const s3 = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region,
      });

      const params = {
        Bucket: config.bucketName,
        Key: fileKey,
        Expires: 60 * 10, // URL expiration time in seconds (5 minutes here)
      };

      return new Promise((resolve, reject) => {
        s3.getSignedUrl("getObject", params, (err, url) => {
          if (err) {
            reject(err);
          } else {
            resolve(url);
          }
        });
      });
    };
  
    const handleDeleteClick = async () => {
      try {
        const deleteResponse = await deleteCall({ callId });
        console.log(deleteResponse); 
        alert("Call deleted successfully");
        navigate(RoutePath.DASHBOARD)
      }
      catch (error) {
        console.log(error);
        alert("Error deleting call");
      }
    }
    
    const agentEvaluationColumns: ColumnsType<AgentEvaluationItem> = [
      {
        title: "Section",
        dataIndex: "question",
        key: "question",
        width: "50%",
      },
      {
        title: "",
        dataIndex: "value",
        key: "value",
        width: "5%",
        render: (value: string) => <span>{value === "Yes" ? "✅" : "❌"}</span>,
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        width: "45%",
      },
    ];

    const abuseDetectionColumns: ColumnsType<AbusiveContentItem> = [
      {
        title: "Content",
        dataIndex: "content",
        key: "content",
        width: "20%",
    },
    {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        width: "65%",
    },
    {
        dataIndex: ["startTimestamp", "endTimestamp"],
        key: "timestamp",
        width: "15%",
        render: (value, record:AbusiveContentItem) => (
          <a onClick={() => handlePlayAudioClick(record.startTimestamp - 5, record.endTimestamp + 5)}>
            <PlayCircleOutlined /> {" "}  Play
          </a>
        ),
      },
    ];

    return (
      <Content>
        <h2 style={{ paddingLeft: "10px" }}>Call Analysis</h2>
        <div className="delete-button-div">
          <Button onClick={handleDeleteClick}>
            <DeleteOutlined />
          </Button>
        </div>
        <div className="call-analysis-main">
          <div>
            <div className="payment-intent-box">
              <Card title="Payment Intent">
                {loading ? (
                  <div>
                    <Spin size="large" />
                  </div>
                ) : (
                  <div>
                    <p>
                      Predicted Intent:{" "}
                      <b>
                        {paymentIntent?.isWilling
                          ? "willing to pay"
                          : paymentIntent?.isWilling === false
                          ? "unwilling to pay"
                          : "unconclusive"}
                      </b>
                    </p>
                    <ul>
                      {paymentIntent.reason.map((reason, index) => (
                        <li key={index}>{reason}</li>
                      ))}
                    </ul>
                    {paymentIntent.isWilling && (
                      <p>
                        Payment Date:{" "}
                        {paymentIntent.paymentDate
                          ? paymentIntent.paymentDate
                          : "Not Specified"}
                      </p>
                    )}
                  </div>
                )}
              </Card>
            </div>
            <div className="agent-evaluation-box">
              <Card title="Agent Evaluation">
                {loading ? (
                  <div>
                    <Spin size="large" />
                  </div>
                ) : (
                  <Table
                    dataSource={agentEvaluation}
                    columns={agentEvaluationColumns}
                    pagination={false}
                    rowKey="section"
                  />
                )}
              </Card>
            </div>
            <div className="abuse-detection-box">
              <Card title="Abuse Detection">
                {loading ? (
                  <div>
                    <Spin size="large" />
                  </div>
                ) : abuseDetection ? (
                  abuseDetection.isAbuseDetected ? (
                    <div>
                      {/* <p><strong>Language:</strong> {abuseDetection.abuseDescription}</p> */}
                      <Table
                        dataSource={abuseDetection.abusiveContent}
                        columns={abuseDetectionColumns}
                        pagination={false}
                        rowKey="content"
                      />
                    </div>
                  ) : (
                    <p>No abusive content detected</p>
                  )
                ) : (
                  <p>Error loading abuse detection data</p>
                )}
              </Card>
            </div>
          </div>
          <div className="play-call-box">
            <Card title="Play Call" style={{ height: "600px" }}>
              {audioUrl && (
                <audio ref={audioRef} controls id="callAudio">
                  <source src={audioUrl} type="audio/mpeg" />
                </audio>
              )}
              <div className="transcript-box" style={{ marginTop: "20px" }}>
                {loading ? (
                  <Spin size="large" />
                ) : (
                  snippets.map((snippet, index) => (
                    <div
                      key={index}
                      data-start={snippet.start}
                      data-end={snippet.end}
                      id={`snippet-${index}`}
                    >
                      <strong>{snippet.speaker}:</strong> {snippet.text}
                    </div>
                  ))
                )}
              </div>
            </Card>
          </div>
        </div>
      </Content>
    );
};

export default CallAnalysis;
