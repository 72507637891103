import React from "react";

type StatusTagProps = {
  status: "completed" | "processing" | "waiting" | "failed";
};

const getStatusStyle = (
  status: "completed" | "processing" | "waiting" | "failed"
): React.CSSProperties => {
  switch (status) {
    case "completed":
      return { backgroundColor: "#63e352", color: "white" };
    case "processing":
      return { backgroundColor: "#8c98e6", color: "white" };
    case "waiting":
      return { backgroundColor: "gray", color: "white" };
    case "failed":
      return { backgroundColor: "red", color: "white" };
    default:
      return {};
  }
};

const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  return (
    <span
      style={{
        ...getStatusStyle(status),
        padding: "5px 10px",
        borderRadius: "5px",
      }}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

const CompletedTag: React.FC = () => <StatusTag status="completed" />;
const ProcessingTag: React.FC = () => <StatusTag status="processing" />;
const WaitingTag: React.FC = () => <StatusTag status="waiting" />;
const FailedTag: React.FC = () => <StatusTag status="failed" />;

export { CompletedTag, ProcessingTag, WaitingTag, FailedTag };
